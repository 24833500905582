<template>
  <div>
    <div class="box-card" id="account-box">
      <div class="page-title">注册</div>
      <el-form
        class="input-box"
        ref="register"
        :rules="formRules"
        :model="dataForm"
      >
        <el-form-item prop="username">
          <el-input
            :placeholder="userNameInputTips"
            v-model="dataForm.username"
          ></el-input>
        </el-form-item>

        <el-form-item prop="validateCode" v-if="usernameIsTelephone">
          <div style="display: flex;">
            <el-input
              class="phoneInput"
              placeholder="请输入手机验证码"
              v-model="dataForm.validateCode"
            ></el-input>
            <el-button
              class="getCodeBtn"
              v-on:click="getPhoneCode"
              :disabled="disabled"
              :loading="sendLoading"
              >{{ sendTips }}</el-button
            >
          </div>
        </el-form-item>

        <el-form-item v-else prop="telephone">
          <el-input
            class="phoneInput"
            placeholder="请输入手机号码"
            v-model="dataForm.telephone"
          ></el-input>
          <el-button
            class="getCodeBtn"
            v-on:click="getPhoneCode"
            :disabled="disabled"
            :loading="sendLoading"
            >{{ sendTips }}</el-button
          >
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            v-model="dataForm.password"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item prop="repassword">
          <el-input
            placeholder="请再次输入密码"
            v-model="dataForm.repassword"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item prop="email" v-if="showEmailInput">
          <el-input
            placeholder="请输入邮箱"
            v-model="dataForm.email"
          ></el-input>
        </el-form-item>
      </el-form>

      <div>
        <router-link class="login-button" :to="{ name: 'Signin' }">
          <el-button>返回登录</el-button>
        </router-link>
        <el-button
          class="login-button"
          type="primary"
          v-on:click="register"
          :loading="signupLoading"
          >注册</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { signup, sendValidateCode } from "@/api";
import { isPoneAvailable } from "@/utils/utils";
export default {
  data() {
    const validatePass1 = (rule, value, callback) => {
      let regNumber = /\d+/; //验证0-9的任意数字最少出现1次。
      let regString = /[a-zA-Z]+/; //验证大小写26个字母任意字母最少出现1次。
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value.length < 8) {
        callback(new Error("密码不得少于8位"));
      } else if (!regNumber.test(value) || !regString.test(value)) {
        callback(new Error("密码必须包含数字和英文"));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.dataForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    const validateUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入帐号"));
      } else if (value.length < 6) {
        callback(new Error("帐号不得少于6位"));
      } else {
        callback();
      }
    };
    return {
      userNameInputTips: "请输入手机号码作为帐号",
      usernameIsTelephone: true, // 帐号是手机号码
      dataForm: {
        username: "",
        password: "",
        repassword: "",
        email: "",
        telephone: "",
        validateCode: ""
      },
      formRules: {
        username: [{ validator: validateUsername, trigger: "blur" }],
        password: [{ validator: validatePass1, trigger: "blur" }],
        repassword: [{ validator: validatePass2, trigger: "blur" }],
        telephone: [{ validator: validatePhone, trigger: "blur" }],
        validateCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ],
        email: [
          {
            required: false,
            message: "请输入正确的邮箱地址",
            trigger: "blur",
            type: "email"
          }
        ]
      },
      sendTips: "获取验证码",
      sendLoading: false, // 验证码按钮加载动画
      signupLoading: false, // 注册按钮的加载动画
      disabled: false, // 获取验证码按钮是否可按
      showEmailInput: false
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    getPhoneCode() {
      // 如果帐号是手机号码,需要赋值
      if (this.userNameInputTips) {
        this.dataForm.telephone = this.dataForm.username;
      }

      if (!isPoneAvailable(this.dataForm.telephone)) {
        this.$message.error("请输入正确的手机号码");
        return 0;
      }

      if (!this.dataForm.telephone) {
        this.$message.error("请输入手机号码");
        return 0;
      }

      this.emailLoading = true;
      this.disabled = true;
      if (this.sendTips !== "获取验证码") {
        return 0;
      }
      let time = 60;

      sendValidateCode({
        telephone: this.dataForm.telephone,
        type: 0
      })
        .then(res => {
          this.$message({
            message: res.data.message || "Success",
            type: "success",
            duration: 5 * 1000
          });
          this.sendTips = time + "秒";

          const timer = setInterval(() => {
            time--;
            this.sendTips = time + "秒";

            if (time === 0) {
              time = 60;
              clearInterval(timer);
              this.sendTips = "获取验证码";
              this.emailLoading = false;
              this.disabled = false;
            }
          }, 1000);
        })
        .catch(res => {
          this.$alert(res, "系统消息");
          this.disabled = false;
          this.emailLoading = false;
        });
    },
    register() {
      this.$refs.register.validate(valid => {
        if (valid) {
          this.signupLoading = true;
          signup({
            username: this.dataForm.username,
            password: this.dataForm.password,
            email: this.dataForm.email,
            telephone: this.dataForm.telephone,
            validateCode: this.dataForm.validateCode
          })
            .then(res => {
              if (res.data.code === 200) {
                this.$alert("注册成功，点击确定跳转至登录界面。", "系统消息", {
                  confirmButtonText: "确定",
                  callback: action => {
                    this.$safePush({
                      name: "Signin",
                      params: { username: this.dataForm.username }
                    });
                  }
                });
              } else {
                this.$alert(res.data.message, "系统消息", {
                  confirmButtonText: "确定",
                  type: "error"
                });
              }
            })
            .catch(err => {
              this.$alert(err, "系统消息", {
                confirmButtonText: "确定",
                type: "error"
              });
            })
            .finally(() => {
              //重置图片验证码
              // this.code = ''// 未有
              // this.refreshCode()
              this.signupLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //验证码专有代码
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    }
    //验证码专有代码结束
  },
  components: {}
};
</script>

<style scoped lang="scss">
.clear {
  clear: both;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  padding: 25px 0px;
}
.input-box {
  width: 380px;
  /* margin-top: 10px; */
  margin: auto;
}
#account-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-button {
  margin-top: 10px;
  margin-left: 10px;
}
.xinghao {
  color: red;
  display: inline-block;
  margin: 12px 10px 0 0;
}

.getCodeBtn {
  margin: 0 0 0 5px;
  width: 110px;
}

.el-form-item__error {
  left: 20px !important;
}

@media screen and (max-width: 405px) {
  .input-box {
    width: 340px;
  }
  .code {
    width: 215px;
  }
  .el-form-item {
    width: 322px;
  }
  .el-input {
    width: 100%;
  }
  .getCodeBtn {
    width: 130px;
  }
}
</style>
